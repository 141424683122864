import $ from 'jquery';
import bootbox from "bootbox";
import { defineModalPlugin } from './define_jquery_plugin';
import { ContactRegisterPresenter, ContactRegisterView } from "./legacy-javascript/contact_register";

function showPictureBox() {
  showPictureBoxForThumbnail(this);
  return false;
}

function showPictureBoxForThumbnail(thumb) {
  var current_thumb = thumb.parentNode;
  var showLinkedImage = function(lk) {
    $("#dialog-current-image").html('<img src="'+$(lk).attr("href")+'" width="100%"/>');
  };
  var move = function(cb) {
    cb($(current_thumb).closest("li")).find("a.card").each(function(_, lk){
      current_thumb = lk;
      showLinkedImage(lk);
    });
  };
  bootbox.dialog({
    size:"large",
    message: '<span id="dialog-current-image"><span>',
    onEscape: true,
    backdrop: true,
    buttons: {
      previous: {
        label: '< Previous',
        className: 'btn-primary float-start',
        callback: function(){
          move(function(e) { return e.prev(); });
          return false;
        }
      },
      next: {
        label: 'Next >',
        className: 'btn-primary',
        callback: function(){
          move(function(e) { return e.next(); });
          return false;

        }
      },
    },
  }).init(function() {
    showLinkedImage(current_thumb);
  });;
}

window.ajexBox = function ajexBox(url, title) {
  $.getJSON(url+".json", function(data) {
    var mbody = $("<div></div").append(data['body']);
    bootbox.dialog({
      size: "large",
      message: mbody,
      title: title
    });

    mbody.on('ajax:success', function({ detail }) {
      const [data, status, xhr] = detail
      $(this).html(data['body'])
    });

  });
}

function showAjexBox() {
  ajexBox($(this).attr('href'), $(this).attr('msg_title'));
  return false;
}

function showHTMLBox() {
  $.getJSON($(this).attr('href')+".json", function(data) {
    bootbox.dialog({
      message: data["description"],
      title: data["title"],
      buttons: {
        success: {
          label: "OK",
          className: "btn-success",
        },
      }
    });
  });
  return false;
}

function showMessageBox() {
  bootbox.dialog({
    title: $(this).attr('msg_title'),
    message: $(this).attr("message"),
    buttons: {
      success: {
        label: "Close",
        className: "btn-success",
      }}});
  $("a.popup-form-btn").on('click', showForm);
  return false;
}

function showForm() {
  bootbox.dialog({
    title: $(this).attr('msg_title'),
    message: "<div id='_form_in_message_box_'>"+$(this).attr("message")+"</div>",
    buttons: {
      cancel: {
        label: "Cancel",
        className: "btn-secondary",
      },
      success: {
        label: $(this).attr('submit_button') || "Send",
        className: "btn-success",
        callback: function(p) {
          $("#_form_in_message_box_").find("form").submit();
        }
      }
    }});
  new ContactRegisterPresenter(new ContactRegisterView($("#_form_in_message_box_")));
  return false;
}


function installPopupHandlers() {
  defineModalPlugin($);

  $("a.card>img").on('click', showPictureBox);
  $("a.case-involvement").on('click', showHTMLBox);
  $(document).on('click', "a.ajex-button", showAjexBox);
  $(document).on('click', "a.popup-message", showMessageBox);
  $(document).on('click', "a.popup-form-btn", showForm);
}

export { installPopupHandlers }
