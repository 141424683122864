import $ from 'jquery'

function queryStringToJSON(qs) {
  qs = qs || location.search.slice(1);

  var pairs = qs.split('&');
  var result = {};
  pairs.forEach(function(p) {
    var pair = p.split('=');
    var key = pair[0];
    var value = decodeURIComponent(pair[1] || '');

    if( result[key] ) {
      if( Object.prototype.toString.call( result[key] ) === '[object Array]' ) {
        result[key].push( value );
      } else {
        result[key] = [ result[key], value ];
      }
    } else {
      result[key] = value;
    }
  });

  return JSON.parse(JSON.stringify(result));
};

function popup_from_url() {
  var urlParams = queryStringToJSON(window.location.search);
  var link = urlParams['popup%5Blink%5D']
  if(!link) return;

  if(!urlParams['popup%5Bmethod%5D']) {
    window.ajexBox(urlParams['popup%5Blink%5D'], urlParams['popup%5Btitle%5D']);
    return;
  }

  if(['delete', 'put'].includes(urlParams['popup%5Bmethod%5D'].toLowerCase())) {
    if(confirm(urlParams['popup%5Bconfirm%5D'])) {
        $.ajax({
          url: link + '.json',
          type: urlParams['popup%5Bmethod%5D'],
          success: function(response) {
            window.location.replace("/dashboard");
          },
	  error: function(response) {
	    alert("Error: " + response.responseText);
	  },
        });
    }
  }
}

export default popup_from_url

