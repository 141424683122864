
import $ from 'jquery';

function installPageControls() {
  function _close(evt, params){
    var bar = $(evt.target).closest(".bar")
    bar.hide();
    bar.each(function(index) {
      $.ajax({url:"/tools/ui/close/" + $(bar[index]).attr('id') + params,
             method:"post",
              async:false});
    });
  }

  var close_bar = function(evt){ _close(evt, ""); };

  $(".close-button").on("click", close_bar);
}

export { installPageControls }
