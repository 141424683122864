export function initCompanyForm() {
  document.addEventListener("turbolinks:load", function() {
      var companySelect = document.getElementById('company_dropdown');
      var inputs = document.querySelectorAll('#fields_only input');
    
      function toggleInputs(disabled) {
        inputs.forEach(function(input) {
          input.disabled = disabled;
          if (disabled) {
              input.classList.add('form-field-disabled');
            } else {
              input.classList.remove('form-field-disabled');
            }
        });
      }
    
      companySelect.addEventListener('change', function() {
        if (this.value) {
          toggleInputs(true);
        } else {
          toggleInputs(false);
        }
      });
    });
}
  