
import $ from 'jquery';

function installEventHandlers() {
  $("#new_event_contact").one("mouseover click", function(evt) {
    $(evt.target).hide();
    $("#contact-form").removeClass("d-none");
  });
}

export { installEventHandlers }