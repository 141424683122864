import $ from 'jquery';
require('imagemapster');

function initImageMap() {
  function onWindowResize() {
    var newWidth = $('#homepage-overview').width();
    if (newWidth < 500) {
      $('img[usemap="#map"]').mapster('resize',newWidth*2, newWidth);
    }
    else {
      $('img[usemap="#map"]').mapster('resize',newWidth);
    }
  }

  if ($("#mapster_wrap_0").length == 0){
    $('img[usemap="#map"]').mapster({stroke: true, fill:true, fillOpacity:0.1, strokeColor:'0000aa', strokeWidth:5,clickNavigate:true });
    $(window).bind('resize',onWindowResize);
  }
}

export { initImageMap }