import $ from 'jquery';

function installEmailChecker(){
  var email_input = $("input.email-taken");
  var old_email = email_input.val();
  email_input.after('<div class="message"></div>')
  email_input.focusout(function(){
    if (old_email !== email_input.val() ) {
      $.getJSON("/tools/email_taken.json?email="+email_input.val(), function(data){
        if ( data.email === email_input.val() && data.exists){
          email_input.next(".message").text('This email has already been taken, please login and then register');
        } else {
          email_input.next(".message").text('');
        }
      });
    }
  });
}

export { installEmailChecker }

