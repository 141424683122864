import $ from 'jquery';
import Mustache from "mustache"

var QueryString = function () {
  // This function is anonymous, is executed immediately and 
  // the return value is assigned to QueryString!
  var query_string = {};
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
        // If first entry with this name
    if (typeof query_string[pair[0]] === "undefined") {
      query_string[pair[0]] = decodeURIComponent(pair[1]);
        // If second entry with this name
    } else if (typeof query_string[pair[0]] === "string") {
      var arr = [ query_string[pair[0]],decodeURIComponent(pair[1]) ];
      query_string[pair[0]] = arr;
        // If third or later entry with this name
    } else {
      query_string[pair[0]].push(decodeURIComponent(pair[1]));
    }
  } 
    return query_string;
}();

function installSearching() {
  return $.map($("#search-query").first(), function(search_box) {
    var timeoutID = null;
    var savedResult = {};

    const search = function(){
      var val = $(search_box).val();
      if (val !== "") {
        if(val in savedResult) {
          renderSearchResult(savedResult[val]);
        }
        else
        {
          savedResult[val] = null;
          $.getJSON("/search/"+encodeURI(val)+".json",
            function(data){
              savedResult[val] = data;
              renderSearchResult(data);
            });
        }
      }
    };

    $(search_box).keyup(function() {
      clearTimeout(timeoutID);
      timeoutID = setTimeout(function() { search(); }, 500);
    });
    $(search_box).val(QueryString.q);
    search();
  });
};

function renderSearchResult(data){
  var template = $('#results-template').html();
  Mustache.parse(template);
  var rendered = Mustache.render(template, {"entries":data});
  $('#search_results .entries').html(rendered);
  $('#search_results').show();
}

export default installSearching


