import * as tocbot from 'tocbot';
import $ from 'jquery';

function fixToc() {
  if ($("h1").length + $("h2").length + $("h3").length > 6) {
    const x = tocbot.init({
      // Where to render the table of contents.
      tocSelector: '#toc',
      // Where to grab the headings to build the table of contents.
      contentSelector: '.main_content',
      // Which headings to grab inside of the contentSelector element.
      headingSelector: 'h1, h2, h3',
      // For headings inside relative or absolute positioned containers within content.
      hasInnerContainers: true,
    });
  }
}
export function installFixToc() {
  fixToc();
}
