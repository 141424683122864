import {Modal} from "bootstrap";

// code copied from bootstrap to make sure that the $.fn.modal is defined
const defineJQueryPlugin = ($, plugin) => {
  const name = plugin.NAME;
  const JQUERY_NO_CONFLICT = $.fn[name];
  $.fn[name] = plugin.jQueryInterface;
  $.fn[name].Constructor = plugin;
  $.fn[name].noConflict = () => {
    $.fn[name] = JQUERY_NO_CONFLICT;
    return plugin.jQueryInterface;
  };
};

function defineModalPlugin($) {
  defineJQueryPlugin($, Modal);

}

export { defineModalPlugin }
