import $ from 'jquery';

function is_touch_device() {
  return 'ontouchstart' in window        // works on most browsers 
     || navigator.maxTouchPoints;       // works on IE10/11 and Surface
};

function lessRefreshUserActionBar() {
  if (is_touch_device()) {
    $("#expand-user-actions").addClass("touch-device");
  }
  $(".cool-sidebar-expand").on('click', function(event) {
    if($(event.target).parents('.content-menu').length > 0) {
      return;
    }
    if($(this).hasClass('cool-sidebar-expand-hovered')) {
      $(this).removeClass('cool-sidebar-expand-hover').removeClass('cool-sidebar-expand-hovered');
    }
    else {
      $(this).addClass('cool-sidebar-expand-hover').addClass('cool-sidebar-expand-hovered');
      $(this).trigger('hover');
    }
  });
}

export { lessRefreshUserActionBar }
