import $ from 'jquery';
import { country_options, state_options } from "../country_select"

function ContactRegisterPresenter(view) {
  this.view = view;
  var that = this;
  view.onSelectCountry(function(evt) {
    that.selectCountry(evt.target.value);
  });
}

ContactRegisterPresenter.prototype.selectCountry = function (country) {
  this.view.reset();
  if (country === "SG" || country === "") {
    this.view.hideState();
    this.view.hideCity();
    this.view.setCity("Singapore");
  } else if (country === "US"){
    this.view.showState();
    this.view.showCity();
  } else {
    this.view.hideState();
    this.view.showCity();
  }

};

function ContactRegisterView(obj, object_name) {
  this.obj = obj;
  if (obj.find("select[id$=state]").length === 0){ return;}
  this.obj_name = obj.find("select[id$=state]").attr("id").split(/_state/)[0];
  this.hideState();
  this.hideCity();

  this.obj.find("#"+this.obj_name+"_country").html(country_options);
  this.obj.find("#"+this.obj_name+"_country").val(this.backup("country"));
  this.obj.find("#"+this.obj_name+"_state").html(state_options);
  this.obj.find("#"+this.obj_name+"_state").val(this.backup("state"));
  this.loaded = false;
}

ContactRegisterView.prototype.backup = function(name) {
  return this.obj.find("input[type='hidden'][name$='["+name+"]']").val();
};

ContactRegisterView.prototype.onSelectCountry = function(callback) {
  this.obj.find("#"+this.obj_name+"_country").on("change", callback);
  this.obj.find("#"+this.obj_name+"_country").change();
};

ContactRegisterView.prototype.reset = function() {
  if (this.loaded) {
    this.obj.find("#"+this.obj_name+"_state").val('');
    this.obj.find("#"+this.obj_name+"_city").val('');
  }
  this.loaded = true;
};

ContactRegisterView.prototype.showState = function() {
  this.obj.find("#"+this.obj_name+"_state").show();
  this.obj.find("#"+this.obj_name+"_state").attr("style", "display: inline !important");
};

ContactRegisterView.prototype.hideState = function() {
  this.obj.find("#"+this.obj_name+"_state").hide();
  this.obj.find("#"+this.obj_name+"_state").attr("style", "display: none !important");
};

ContactRegisterView.prototype.showCity = function() {
  this.obj.find("#"+this.obj_name+"_city").show();
  this.obj.find("#"+this.obj_name+"_city").attr("style", "display: inline !important");
};

ContactRegisterView.prototype.hideCity = function() {
  this.obj.find("#"+this.obj_name+"_city").hide();
  this.obj.find("#"+this.obj_name+"_city").attr("style", "display: none !important");
};

ContactRegisterView.prototype.setCity = function(city) {
  this.obj.find("#"+this.obj_name+"_city").val(city);
};

function installContactForm() {
  $(".contact-register").each(function(index, obj) {
    new ContactRegisterPresenter(new ContactRegisterView($(obj)));
  });
}

export { installContactForm, ContactRegisterPresenter, ContactRegisterView }